import * as moment from 'moment';


export class ApplicationException {
  message: string | undefined;
  stackTrace: string | undefined;
}

export class Attachment {
  idAttachment: number | undefined;
  idAttachmentType: AttachmentTypeEnum | undefined;
  fileName: string | undefined;
  fileUrl: string | undefined;
}

export enum AttachmentTypeEnum {
  CONTRACT = 1,
  DOCUMENTATION = 2,
  OTHER = 3,
  EMAIL = 4,
}

export class AuthRefreshTokenCommand {
  username: string | undefined;
  refreshToken: string | undefined;
  ipAddress: string | undefined;
}

export class AuthResetPasswordCommand {
  usernameOrEmail: string | undefined;
  verificationCode: string | undefined;
  password: string | undefined;
  confirmPassword: string | undefined;
}

export class AuthTokenCommand {
  username: string | undefined;
  password: string | undefined;
  ipAddress: string | undefined;
}

export class AuthUserRole {
  idAuthUserRole: AuthUserRoleEnum | undefined;
  name: string | undefined;
}

export enum AuthUserRoleEnum {
  ADMIN = 1,
  USER = 2,
}

export class AuthVerifyResetPasswordCodeCommand {
  usernameOrEmail: string | undefined;
  verificationCode: string | undefined;
}

export class AuthenticationToken {
  idAuthUser: number | undefined;
  idUser: number | undefined;
  username: string | undefined;
  accessToken: string | undefined;
  expiresIn: number | undefined;
  refreshToken: string | undefined;
  refreshTokenExpiresIn: number | undefined;
}

export class BillingInformationSaveCommand {
  idBillingInformation: number | undefined;
  idCustomer: number | undefined;
  businessName: string | undefined;
  idCountry: number | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  vatNumber: string | undefined;
  sdi: string | undefined;
  pec: string | undefined;
}

export class ChannelManagerCreateCommand {
  name: string | undefined;
  notes: string | undefined;
}

export class ChannelManagerItem {
  idChannelManager: number | undefined;
  name: string | undefined;
  notes: string | undefined;
}

export class ChannelManagerListForDropDownResponse {
  items: ChannelManagerSummary[] | undefined;
}

export class ChannelManagerListResponse {
  items: ChannelManagerItem[] | undefined;
}

export class ChannelManagerProcessingState {
  idChannelManagerProcessingState: ChannelManagerProcessingStateEnum | undefined;
  name: string | undefined;
}

export enum ChannelManagerProcessingStateEnum {
  FIRST_CONTACT = 1,
  PROCESSING = 2,
  SOLICIT = 3,
  CONNECTING = 4,
  CONNECTED = 5,
  NO = 6,
}

export class ChannelManagerSummary {
  idChannelManager: number | undefined;
  name: string | undefined;
}

export class ChannelManagerUpdateCommand {
  idChannelManager: number | undefined;
  name: string | undefined;
  notes: string | undefined;
}

export class City {
  idCity: number | undefined;
  regionCode: number | undefined;
  regionName: string | undefined;
  code: string | undefined;
  name: string | undefined;
  acronym: string | undefined;
}

export class CityListForDropDownResponse {
  items: CitySummary[] | undefined;
}

export class CityListQueryResponse {
  items: City[] | undefined;
}

export class CitySummary {
  idCity: number | undefined;
  name: string | undefined;
}

export class CommercialManagement {
  idCommercialManagement: number | undefined;
  name: string | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  city: string | undefined;
  province: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  note: string | undefined;
  hotelsCount: number | undefined;
  roomsCount: number | undefined;
  dealProcessingState: DealProcessingState | undefined;
}

export class CommercialManagementCreateCommand {
  name: string | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  city: string | undefined;
  province: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  note: string | undefined;
}

export class CommercialManagementListForDropDownResponse {
  items: CommercialManagementSummary[] | undefined;
}

export class CommercialManagementListItem {
  idCommercialManagement: number | undefined;
  idDealProcessingState: DealProcessingStateEnum | undefined;
  idOwner: number | undefined;
  owner: string | undefined;
  name: string | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  city: string | undefined;
  province: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  note: string | undefined;
  representativeName: string | undefined;
  representativeEmail: string | undefined;
  hotelsCount: number | undefined;
  roomsCount: number | undefined;
  dealProcessingState: DealProcessingState | undefined;
}

export class CommercialManagementListQuery {
}

export class CommercialManagementListResponse {
  items: CommercialManagementListItem[] | undefined;
  hotelCounts: any | undefined;
}

export class CommercialManagementSummary {
  idCommercialManagement: number | undefined;
  name: string | undefined;
}

export class CommercialManagementUpdateCommand {
  idCommercialManagement: number | undefined;
  idDealProcessingState: DealProcessingStateEnum | undefined;
  idOwner: number | undefined;
  name: string | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  city: string | undefined;
  province: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  note: string | undefined;
}

export class CommunicationAttachment {
  idCommunicationAttachment: number | undefined;
  idAttachment: number | undefined;
  idCommunication: number | undefined;
  attachment: Attachment | undefined;
}

export class CommunicationAttachmentDeleteCommand {
  idAttachment: number | undefined;
}

export class CommunicationAttachmentListQueryResponse {
  items: CommunicationAttachment[] | undefined;
}

export class CommunicationCreateCommand {
  idHotel: number | undefined;
  idCommercialManagement: number | undefined;
  idCommunicationMethod: CommunicationMethodEnum | undefined;
  idSalesActivitiesStatus: SalesActivitiesStatusEnum | undefined;
  idCommunicationDirection: CommunicationDirectionEnum | undefined;
  emailObject: string | undefined;
  idUserSenderOrRecipient: number | undefined;
  communicationReceivedDate: moment.Moment | undefined;
  notes: string | undefined;
  nameUserCreatedBy: string | undefined;
}

export class CommunicationDirection {
  idCommunicationDirection: CommunicationDirectionEnum | undefined;
  name: string | undefined;
}

export enum CommunicationDirectionEnum {
  SENT = 1,
  RECEIVED = 2,
}

export class CommunicationItem {
  idCommunication: number | undefined;
  idCommunicationMethod: CommunicationMethodEnum | undefined;
  idSalesActivitiesStatus: SalesActivitiesStatusEnum | undefined;
  idCommunicationDirection: CommunicationDirectionEnum | undefined;
  emailObject: string | undefined;
  idUserSenderOrRecipient: number | undefined;
  communicationReceivedDate: moment.Moment | undefined;
  notes: string | undefined;
  userCreatedBy: string | undefined;
  attachmentCount: number | undefined;
  updatedAt: moment.Moment | undefined;
}

export class CommunicationListResponse {
  items: CommunicationItem[] | undefined;
}

export class CommunicationMethod {
  idCommunicationMethod: CommunicationMethodEnum | undefined;
  name: string | undefined;
}

export enum CommunicationMethodEnum {
  EMAIL = 1,
  PHONE_CALL = 2,
  VIDEO_CALL = 3,
  MEETING = 4,
  BACK_OFFICE = 5,
}

export class CommunicationUpdateCommand {
  idCommunication: number | undefined;
  idCommunicationMethod: CommunicationMethodEnum | undefined;
  idSalesActivitiesStatus: SalesActivitiesStatusEnum | undefined;
  idCommunicationDirection: CommunicationDirectionEnum | undefined;
  emailObject: string | undefined;
  idUserSenderOrRecipient: number | undefined;
  communicationReceivedDate: moment.Moment | undefined;
  communicationsSentOrReceived: boolean | undefined;
  notes: string | undefined;
}

export class ContractAgreementFormat {
  idContractAgreementFormat: ContractAgreementFormatEnum | undefined;
  name: string | undefined;
}

export enum ContractAgreementFormatEnum {
  EMAIL = 1,
  LTS = 2,
  HOTEL = 3,
  GROUP = 4,
}

export class ContractAttachment {
  idContractAttachment: number | undefined;
  idAttachment: number | undefined;
  idContract: number | undefined;
  attachment: Attachment | undefined;
}

export class ContractAttachmentDeleteCommand {
  idAttachment: number | undefined;
}

export class ContractAttachmentListQueryResponse {
  items: ContractAttachment[] | undefined;
}

export class ContractPaymentMode {
  idContractPaymentMode: ContractPaymentModeEnum | undefined;
  name: string | undefined;
}

export enum ContractPaymentModeEnum {
  PAY_PER_LINK = 1,
  VIRTUAL_CARD = 2,
  CHARGE_UPON_CONFIRMATION = 3,
  CHARGE_UPON_CHECK_IN = 4,
  CHARGE_UPON_CHECK_OUT = 5,
  ON_INVOICE_VIEW = 6,
  FIVE_DAYS_FROM_INVOICE_EMISSION = 7,
  FIFTEEN_DAYS_FROM_INVOICE_EMISSION = 8,
  THIRTY_DAYS_FROM_INVOICE_EMISSION = 9,
  SIXTY_DAYS_FROM_INVOICE_EMISSION = 10,
}

export class ContractPlanRateReadQueryResponse {
  idContract: number | undefined;
  idContractPlanRate: number | undefined;
  idContractType: ContractTypeEnum | undefined;
  hotelRoomGroups: HotelRoomGroup[] | undefined;
}

export class ContractPlanRateSaveCommand {
  idContract: number | undefined;
  idHotel: number | undefined;
  idContractType: ContractTypeEnum | undefined;
  hotelRoomGroups: HotelRoomGroup[] | undefined;
}

export class ContractService {
  idContractService: number | undefined;
  idContract: number | undefined;
  idContractServiceType: ContractServiceTypeEnum | undefined;
  idStayType: StayTypeEnum | undefined;
  idPerType: PerTypeEnum | undefined;
  contractServiceRestaurant: ContractServiceRestaurant | undefined;
  contractServicePet: ContractServicePet | undefined;
  contractServiceLateCheckOut: ContractServiceLateCheckOut | undefined;
  contractServiceEarlyCheckIn: ContractServiceEarlyCheckIn | undefined;
}

export class ContractServiceEarlyCheckIn {
  idContractServiceEarlyCheckIn: number | undefined;
  idContractService: number | undefined;
  rate: number | undefined;
}

export class ContractServiceLateCheckOut {
  idContractServiceLateCheckOut: number | undefined;
  idContractService: number | undefined;
  rate: number | undefined;
}

export class ContractServiceListResponse {
  items: ContractService[] | undefined;
}

export class ContractServicePet {
  idContractServicePet: number | undefined;
  idContractService: number | undefined;
  rate: number | undefined;
}

export class ContractServiceRestaurant {
  idContractServiceRestaurant: number | undefined;
  idContractService: number | undefined;
  lunchRate: number | undefined;
  dinnerRate: number | undefined;
  lunchboxRate: number | undefined;
  notes: string | undefined;
}

export class ContractServiceSaveCommand {
  idContract: number | undefined;
  items: ContractService[] | undefined;
}

export class ContractServiceType {
  idContractServiceType: ContractServiceTypeEnum | undefined;
  name: string | undefined;
}

export enum ContractServiceTypeEnum {
  RESTAURANT = 1,
  PET = 2,
  EARLY_CHECKIN = 3,
  LATE_CHECKOUT = 4,
}

export class ContractState {
  idContractState: ContractStateEnum | undefined;
  name: string | undefined;
}

export enum ContractStateEnum {
  ACTIVE = 1,
  INACTIVE = 2,
  EXPIRED = 3,
}

export class ContractType {
  idContractType: ContractTypeEnum | undefined;
  name: string | undefined;
}

export enum ContractTypeEnum {
  NEGOTIATED_RATES = 1,
  ON_REQUEST_RATES = 2,
  NOT_NEGOTIATED = 3,
}

export class Country {
  idCountry: number | undefined;
  name: string | undefined;
}

export class CountryListQueryResponse {
  items: Country[] | undefined;
}

export class CustomerContractAttachment {
  idCustomerContractAttachment: number | undefined;
  idAttachment: number | undefined;
  idCustomerContract: number | undefined;
  attachment: Attachment | undefined;
}

export class CustomerContractAttachmentDeleteCommand {
  idAttachment: number | undefined;
}

export class CustomerContractAttachmentListQueryResponse {
  items: CustomerContractAttachment[] | undefined;
}

export class CustomerContractCreateCommand {
  idCustomer: number | undefined;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  signedOnDate: moment.Moment | undefined;
  idCustomerContractPaymentMode: CustomerContractPaymentModeEnum | undefined;
  plafond: number | undefined;
}

export class CustomerContractHotelRateFlat {
  idCustomerContractHotelRate: number | undefined;
  idCustomerContract: number | undefined;
  singleRate: number | undefined;
  doubleRate: number | undefined;
  tripleRate: number | undefined;
  lunchRate: number | undefined;
  dinnerRate: number | undefined;
  boxRate: number | undefined;
  customerContractHotelRatesInTransportHub: CustomerContractHotelRateInTransportHub[] | undefined;
}

export class CustomerContractHotelRateIdTransportHubListQueryResponse {
  items: number[] | undefined;
}

export class CustomerContractHotelRateInTransportHub {
  idCustomerContractHotelRateInTransportHub: number | undefined;
  idTransportHub: number | undefined;
  iataCode: string | undefined;
  name: string | undefined;
  iataAndName: string | undefined;
}

export class CustomerContractHotelRateListQueryResponse {
  items: CustomerContractHotelRateFlat[] | undefined;
}

export class CustomerContractHotelRatePercentage {
  idCustomerContractHotelRate: number | undefined;
  idCustomerContract: number | undefined;
  ratePercentage: number | undefined;
}

export class CustomerContractHotelSaveFlatRateCommand {
  idCustomerContractHotelRate: number | undefined;
  idCustomerContract: number | undefined;
  idCustomerContractType: CustomerContractTypeEnum | undefined;
  idsTransportHub: number[] | undefined;
  singleRate: number | undefined;
  doubleRate: number | undefined;
  tripleRate: number | undefined;
  lunchRate: number | undefined;
  dinnerRate: number | undefined;
  boxRate: number | undefined;
}

export class CustomerContractHotelSavePercentageRateCommand {
  idCustomerContractHotelRate: number | undefined;
  idCustomerContract: number | undefined;
  idCustomerContractType: CustomerContractTypeEnum | undefined;
  ratePercentage: number | undefined;
}

export class CustomerContractItem {
  idCustomerContract: number | undefined;
  idCustomer: number | undefined;
  idCustomerContractType: CustomerContractTypeEnum | undefined;
  idContractState: ContractStateEnum | undefined;
  idCustomerContractPaymentMode: CustomerContractPaymentModeEnum | undefined;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  signedOnDate: moment.Moment | undefined;
  plafond: number | undefined;
}

export class CustomerContractListResponse {
  items: CustomerContractItem[] | undefined;
}

export class CustomerContractPaymentMode {
  idCustomerContractPaymentMode: CustomerContractPaymentModeEnum | undefined;
  name: string | undefined;
}

export enum CustomerContractPaymentModeEnum {
  VIEW_INVOICE = 1,
  BANK_TRANSFER_30_DAYS = 2,
  BANK_TRANSFER_60_DAYS = 3,
  CORPORATE_CARD = 4,
  WARRANTY = 5,
}

export class CustomerContractTransportRate {
  idCustomerContractTransportRate: number | undefined;
  idAttachment: number | undefined;
  idCustomerContract: number | undefined;
  ratePercentage: number | undefined;
  attachments: Attachment[] | undefined;
}

export class CustomerContractTransportRateSaveCommand {
  idCustomerContractTransportRate: number | undefined;
  idCustomerContract: number | undefined;
  ratePercentage: number | undefined;
}

export class CustomerContractType {
  idCustomerContractType: CustomerContractTypeEnum | undefined;
  name: string | undefined;
}

export enum CustomerContractTypeEnum {
  PERCENTAGE_RATE = 1,
  FLAT_RATE = 2,
}

export class CustomerContractUpdateCommand {
  idCustomerContract: number | undefined;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  signedOnDate: moment.Moment | undefined;
  idCustomerContractPaymentMode: CustomerContractPaymentModeEnum | undefined;
  plafond: number | undefined;
}

export class CustomerCreateCommand {
  name: string | undefined;
  idLanguageCulture: number | undefined;
}

export class CustomerItem {
  idCustomer: number | undefined;
  idBillingInformation: number | undefined;
  idLanguageCulture: number | undefined;
  idOwner: number | undefined;
  name: string | undefined;
  businessName: string | undefined;
  idCountry: number | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  vatNumber: string | undefined;
  sdi: string | undefined;
  pec: string | undefined;
  ownerName: string | undefined;
  idDealProcessingState: DealProcessingStateEnum | undefined;
  idContractState: ContractStateEnum | undefined;
  idCustomerContractType: CustomerContractTypeEnum | undefined;
}

export class CustomerListQuery {
}

export class CustomerListResponse {
  items: CustomerItem[] | undefined;
}

export class CustomerUpdateCommand {
  idCustomer: number | undefined;
  name: string | undefined;
  idLanguageCulture: number | undefined;
}

export class DealProcessingState {
  idDealProcessingState: DealProcessingStateEnum | undefined;
  name: string | undefined;
  ordinal: number | undefined;
}

export enum DealProcessingStateEnum {
  FIRST_CONTACT = 1,
  PROCESSING = 2,
  SOLICIT = 3,
  DEAL_NO_INFO = 4,
  DEAL = 5,
  ONLY_ON_REQUEST = 6,
  NN_ON_REQUEST = 7,
  TEMPORARILY_CLOSED = 8,
  UNINTERESTED = 9,
  OFF_TARGET = 10,
  CLOSED = 11,
  TO_CONTACT = 12,
}

export class EnumListQueryResponse {
  authUserRoles: AuthUserRole[] | undefined;
  dealProcessingStates: DealProcessingState[] | undefined;
  hotelRoomExclusiveServices: HotelRoomExclusiveService[] | undefined;
  hotelRoomInformationSources: HotelRoomInformationSource[] | undefined;
  hotelRoomOccupancies: HotelRoomOccupancy[] | undefined;
  hotelRoomTypes: HotelRoomType[] | undefined;
  contractAgreementFormats: ContractAgreementFormat[] | undefined;
  contractPaymentModes: ContractPaymentMode[] | undefined;
  contractStates: ContractState[] | undefined;
  contractTypes: ContractType[] | undefined;
  contractServiceTypes: ContractServiceType[] | undefined;
  stayTypes: StayType[] | undefined;
  perTypes: PerType[] | undefined;
  representativeDepartements: RepresentativeDepartement[] | undefined;
  communicationMethods: CommunicationMethod[] | undefined;
  salesActivitiesStatus: SalesActivitiesStatus[] | undefined;
  communicationDirections: CommunicationDirection[] | undefined;
  hotelIropContacts: HotelIropContactType[] | undefined;
  customerContractTypes: CustomerContractType[] | undefined;
  channelManagerProcessingStates: ChannelManagerProcessingState[] | undefined;
  customerContractPaymentModes: CustomerContractPaymentMode[] | undefined;
  transportHubTypes: TransportHubType[] | undefined;
}

export class Hotel {
  idHotel: number | undefined;
  idTimeZone: number | undefined;
  idCity: number | undefined;
  hasCommercialManagement: boolean | undefined;
  idCommercialManagement: number | undefined;
  idHotelChain: number | undefined;
  idChannelManager: number | undefined;
  idLanguageCulture: number | undefined;
  idChannelManagerProcessingState: ChannelManagerProcessingStateEnum | undefined;
  uniqueCode: string | undefined;
  name: string | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  municipality: string | undefined;
  rooms: number | undefined;
  roomsPMR: number | undefined;
  hotelCategory: number | undefined;
  sigleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  quadrupleRooms: number | undefined;
  hasRestaurant: boolean | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  isChannelManagerActivated: boolean | undefined;
  externalIdChannelManager: string | undefined;
  emailAccountChannelManager: string | undefined;
  channelManagerProcessingStateUpdatedAt: moment.Moment | undefined;
  notes: string | undefined;
  dealProcessingState: DealProcessingState | undefined;
  channelManagerProcessingState: ChannelManagerProcessingState | undefined;
  idsTransportHub: number[] | undefined;
  commercialManagement: CommercialManagement | undefined;
  hotelChain: HotelChain | undefined;
  channelManager: ChannelManagerItem | undefined;
}

export class HotelBookingContact {
  idHotelBookingContact: number | undefined;
  email: string | undefined;
  notificationsPrefix: string | undefined;
  notificationsPhone: string | undefined;
  contactCenterPrefix: string | undefined;
  contactCenterPhone: string | undefined;
}

export class HotelBookingContactListResponse {
  items: HotelBookingContact[] | undefined;
}

export class HotelBookingContactSaveCommand {
  idHotel: number | undefined;
  list: HotelBookingContact[] | undefined;
}

export class HotelChain {
  idHotelChain: number | undefined;
  name: string | undefined;
  description: string | undefined;
}

export class HotelChainCreateCommand {
  name: string | undefined;
  description: string | undefined;
}

export class HotelChainListForDropDownResponse {
  items: HotelChainSummary[] | undefined;
}

export class HotelChainListItem {
  idHotelChain: number | undefined;
  name: string | undefined;
  description: string | undefined;
  hotelsCount: number | undefined;
}

export class HotelChainListResponse {
  items: HotelChainListItem[] | undefined;
}

export class HotelChainSummary {
  idHotelChain: number | undefined;
  name: string | undefined;
}

export class HotelChainUpdateCommand {
  idHotelChain: number | undefined;
  name: string | undefined;
  description: string | undefined;
}

export class HotelCompleteListItem {
  idHotel: number | undefined;
  hotel: string | undefined;
  transportHubs: string | undefined;
  provincia: string | undefined;
  indirizzo: string | undefined;
  comune: string | undefined;
  cap: string | undefined;
  gestioneCommerciale: string | undefined;
  contattoGestioneCommerciale: string | undefined;
  catena: string | undefined;
  categoria: string | undefined;
  camere: number | undefined;
  camerePMR: number | undefined;
  ristorante: string | undefined;
  camereSingole: number | undefined;
  camereDoppie: number | undefined;
  camereTriple: number | undefined;
  camereQuadruple: number | undefined;
  email: string | undefined;
  telefono: string | undefined;
  coordinate: string | undefined;
  languageCulture: string | undefined;
  channelManager: string | undefined;
  externalIdChannelManager: string | undefined;
  isChannelManagerActivated: boolean | undefined;
  note: string | undefined;
  referenteOperativo: string | undefined;
  contattoOperativo: string | undefined;
  owner: string | undefined;
  status: string | undefined;
  inizioContratto: string | undefined;
  fineContratto: string | undefined;
  firmato: string | undefined;
  statoContratto: string | undefined;
  referenteContratto: string | undefined;
  emailReferenteContratto: string | undefined;
  rinnovoTacito: string | undefined;
  modalitàPagamento: string | undefined;
  formatoAccordo: string | undefined;
  cityTax: number | undefined;
  cityTaxNote: string | undefined;
  allegati: string | undefined;
  tariffe: string | undefined;
  standardDayUseSingola: number | undefined;
  standardDayUseDus: number | undefined;
  standardDayUseDoppia: number | undefined;
  standardDayUseTripla: number | undefined;
  standardDayUseQuadrupla: number | undefined;
  standardRoomOnlySingola: number | undefined;
  standardRoomOnlyDus: number | undefined;
  standardRoomOnlyDoppia: number | undefined;
  standardRoomOnlyTripla: number | undefined;
  standardRoomOnlyQuadrupla: number | undefined;
  standardBedAndBrekfastSingola: number | undefined;
  standardBedAndBrekfastDus: number | undefined;
  standardBedAndBrekfastDoppia: number | undefined;
  standardBedAndBrekfastTripla: number | undefined;
  standardBedAndBrekfastQuadrupla: number | undefined;
  superiorDayUseSingola: number | undefined;
  superiorDayUseDus: number | undefined;
  superiorDayUseDoppia: number | undefined;
  superiorDayUseTripla: number | undefined;
  superiorDayUseQuadrupla: number | undefined;
  superiorRoomOnlySingola: number | undefined;
  superiorRoomOnlyDus: number | undefined;
  superiorRoomOnlyDoppia: number | undefined;
  superiorRoomOnlyTripla: number | undefined;
  superiorRoomOnlyQuadrupla: number | undefined;
  superiorBedAndBrekfastSingola: number | undefined;
  superiorBedAndBrekfastDus: number | undefined;
  superiorBedAndBrekfastDoppia: number | undefined;
  superiorBedAndBrekfastTripla: number | undefined;
  superiorBedAndBrekfastQuadrupla: number | undefined;
  pranzo: number | undefined;
  cena: number | undefined;
  box: number | undefined;
  noteRistorante: string | undefined;
  tariffaPet: number | undefined;
  tipoTariffaPet: string | undefined;
  earlyCheckin: number | undefined;
  tipoEarlyCheckin: string | undefined;
  lateCheckout: number | undefined;
  tipoLateCheckout: string | undefined;
  reminderGiornaliero: boolean | undefined;
  emailReminderGiornaliero: string | undefined;
  telefonoReminderGiornaliero: string | undefined;
  cellulareReminderGiornaliero: string | undefined;
  alertAperturaServizio: boolean | undefined;
  emailAlertAperturaServizio: string | undefined;
  telefonoAlertAperturaServizio: string | undefined;
  cellulareAlertAperturaServizio: string | undefined;
  emailPrenotazioni: string | undefined;
  telefonoPrenotazioni: string | undefined;
  cellularePrenotazioni: string | undefined;
}

export class HotelContractCloneCommand {
  idContractSource: number | undefined;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
}

export class HotelContractCreateCommand {
  idHotel: number | undefined;
  idContractAgreementFormat: ContractAgreementFormatEnum | undefined;
  idContractPaymentMode: ContractPaymentModeEnum | undefined;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  signedOnDate: moment.Moment | undefined;
  referentName: string | undefined;
  referentEmail: string | undefined;
  tacitRenewal: boolean | undefined;
  cityTax: number | undefined;
  cityTaxNotes: string | undefined;
}

export class HotelContractItem {
  idContract: number | undefined;
  idHotel: number | undefined;
  idContractType: ContractTypeEnum | undefined;
  idContractState: ContractStateEnum | undefined;
  idContractAgreementFormat: ContractAgreementFormatEnum | undefined;
  idContractPaymentMode: ContractPaymentModeEnum | undefined;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  signedOnDate: moment.Moment | undefined;
  tacitlyRenewedAt: moment.Moment | undefined;
  referentName: string | undefined;
  referentEmail: string | undefined;
  tacitRenewal: boolean | undefined;
  cityTax: number | undefined;
  cityTaxNotes: string | undefined;
}

export class HotelContractListResponse {
  items: HotelContractItem[] | undefined;
}

export class HotelContractUpdateCommand {
  idContract: number | undefined;
  idContractAgreementFormat: ContractAgreementFormatEnum | undefined;
  idContractPaymentMode: ContractPaymentModeEnum | undefined;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  signedOnDate: moment.Moment | undefined;
  tacitlyRenewedAt: moment.Moment | undefined;
  referentName: string | undefined;
  referentEmail: string | undefined;
  tacitRenewal: boolean | undefined;
  cityTax: number | undefined;
  cityTaxNotes: string | undefined;
}

export class HotelCreateCommand {
  name: string | undefined;
  idCity: number | undefined;
  idsTransportHub: number[] | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  idChannelManager: number | undefined;
  idLanguageCulture: number | undefined;
  idTimeZone: number | undefined;
  idOwner: number | undefined;
  idDealProcessingState: DealProcessingStateEnum | undefined;
  idCommercialManagement: number | undefined;
  idHotelChain: number | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  municipality: string | undefined;
  rooms: number | undefined;
  roomsPMR: number | undefined;
  hotelCategory: number | undefined;
  sigleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  quadrupleRooms: number | undefined;
  hasRestaurant: boolean | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  isChannelManagerActivated: boolean | undefined;
  idChannelManagerProcessingState: ChannelManagerProcessingStateEnum | undefined;
  channelManagerProcessingStateUpdatedAt: moment.Moment | undefined;
  externalIdChannelManager: string | undefined;
  notes: string | undefined;
}

export class HotelExcelExportResponse {
  items: HotelCompleteListItem[] | undefined;
}

export class HotelIropContact {
  idHotelIropContact: number | undefined;
  idHotel: number | undefined;
  idHotelIropContactType: HotelIropContactTypeEnum | undefined;
  isActive: boolean | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  whatsAppPrefix: string | undefined;
  whatsAppPhoneNumber: string | undefined;
}

export class HotelIropContactListResponse {
  items: HotelIropContact[] | undefined;
}

export class HotelIropContactSaveCommand {
  idHotel: number | undefined;
  list: HotelIropContact[] | undefined;
}

export class HotelIropContactType {
  idHotelIropContactType: HotelIropContactTypeEnum | undefined;
  name: string | undefined;
}

export enum HotelIropContactTypeEnum {
  DAILY_REMIND = 1,
  ON_SERVICE_CREATED = 2,
}

export class HotelListItem {
  idHotel: number | undefined;
  transportHubs: string | undefined;
  name: string | undefined;
  idCommercialManagement: number | undefined;
  rooms: number | undefined;
  idDealProcessingState: DealProcessingStateEnum | undefined;
  idContractType: ContractTypeEnum | undefined;
  idContractState: ContractStateEnum | undefined;
  idOwner: number | undefined;
  representativeEmail: string | undefined;
  updatedAt: moment.Moment | undefined;
  roomAvailabilityCommunicationMode: HotelRoomAvailabilityCommunicationMode | undefined;
}

export class HotelListResponse {
  items: HotelListItem[] | undefined;
  totalRows: number | undefined;
}

export class HotelRoom {
  idHotelRoom: number | undefined;
  idHotelRoomOccupancy: HotelRoomOccupancyEnum | undefined;
  idHotelRoomExclusiveService: HotelRoomExclusiveServiceEnum | undefined;
  hotelRoomPlanRate: HotelRoomPlanRate | undefined;
}

export class HotelRoomAvailabilityCommunicationMode {
  hasChannelManager: boolean | undefined;
  hasDailyRemind: boolean | undefined;
  hasOnServiceCreated: boolean | undefined;
  notAvailable: boolean | undefined;
}

export class HotelRoomExclusiveService {
  idHotelRoomExclusiveService: HotelRoomExclusiveServiceEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomExclusiveServiceEnum {
  ROOM_ONLY = 1,
  DAY_USE = 2,
  BED_AND_BREAKFAST = 3,
}

export class HotelRoomGroup {
  idHotelRoomGroup: number | undefined;
  idHotelRoomType: HotelRoomTypeEnum | undefined;
  hotelRooms: HotelRoom[] | undefined;
}

export class HotelRoomInformationSource {
  idHotelRoomInformationSource: HotelRoomInformationSourceEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomInformationSourceEnum {
  HOTEL = 1,
  BAKUUN = 2,
  VERTICAL_BOOKING = 3,
}

export class HotelRoomOccupancy {
  idHotelRoomOccupancy: HotelRoomOccupancyEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomOccupancyEnum {
  SINGLE = 1,
  DOUBLE_SINGLE_USE = 2,
  DOUBLE = 3,
  TRIPLE = 4,
  QUADRUPLE = 5,
}

export class HotelRoomPlanRate {
  idHotelRoomPlanRate: number | undefined;
  amount: number | undefined;
  bestAvailableRateSalePercentage: number | undefined;
}

export class HotelRoomType {
  idHotelRoomType: HotelRoomTypeEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomTypeEnum {
  STANDARD = 1,
  SUPERIOR = 2,
}

export class HotelSendHotelCommunicationCommand {
  idHotel: number | undefined;
  idHotelIropContactType: HotelIropContactTypeEnum | undefined;
}

export class HotelUpdateCommand {
  idHotel: number | undefined;
  name: string | undefined;
  idsTransportHub: number[] | undefined;
  idCity: number | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  idTimeZone: number | undefined;
  hasCommercialManagement: boolean | undefined;
  idCommercialManagement: number | undefined;
  idHotelChain: number | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  municipality: string | undefined;
  rooms: number | undefined;
  roomsPMR: number | undefined;
  hotelCategory: number | undefined;
  sigleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  quadrupleRooms: number | undefined;
  hasRestaurant: boolean | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  idChannelManager: number | undefined;
  idLanguageCulture: number | undefined;
  isChannelManagerActivated: boolean | undefined;
  idChannelManagerProcessingState: ChannelManagerProcessingStateEnum | undefined;
  channelManagerProcessingStateUpdatedAt: moment.Moment | undefined;
  externalIdChannelManager: string | undefined;
  notes: string | undefined;
}

export class LanguageCulture {
  idLanguageCulture: number | undefined;
  languageCultureName: string | undefined;
  countryName: string | undefined;
}

export class LanguageCultureListQueryResponse {
  items: LanguageCulture[] | undefined;
}

export class OwnershipCreateCommand {
  idHotel: number | undefined;
  idCustomer: number | undefined;
  idCommercialManagement: number | undefined;
  idDealProcessingState: DealProcessingStateEnum | undefined;
  idUserOwner: number | undefined;
}

export class OwnershipItem {
  idOwnership: number | undefined;
  idUserOwner: number | undefined;
  idDealProcessingState: DealProcessingStateEnum | undefined;
}

export class OwnershipUpdateCommand {
  idOwnership: number | undefined;
  idUserOwner: number | undefined;
  idDealProcessingState: DealProcessingStateEnum | undefined;
}

export class PerType {
  idPerType: PerTypeEnum | undefined;
  name: string | undefined;
}

export enum PerTypeEnum {
  PER_PERSON = 1,
  PER_ROOM = 2,
}

export class RepresentativeCreateCommand {
  idHotel: number | undefined;
  idCommercialManagement: number | undefined;
  idCustomer: number | undefined;
  name: string | undefined;
  idRepresentativeDepartement: RepresentativeDepartementEnum | undefined;
  role: string | undefined;
  isMainRepresentative: boolean | undefined;
  operativeEmail: string | undefined;
  prefixPhone: string | undefined;
  phoneNumber: string | undefined;
  prefixMobilePhone: string | undefined;
  mobilePhoneNumber: string | undefined;
}

export class RepresentativeDepartement {
  idRepresentativeDepartement: RepresentativeDepartementEnum | undefined;
  name: string | undefined;
}

export enum RepresentativeDepartementEnum {
  ADMINISTRATION = 1,
  FRONT_OFFICE = 2,
  RESERVATION = 3,
  COMMERCIAL_S_M = 4,
  MANAGEMENT = 5,
  F_B = 6,
  EVENTS_GROUP = 7,
  MICE = 8,
  REVENUE = 9,
  IT = 10,
}

export class RepresentativeItem {
  idRepresentative: number | undefined;
  name: string | undefined;
  idRepresentativeDepartement: RepresentativeDepartementEnum | undefined;
  role: string | undefined;
  isMainRepresentative: boolean | undefined;
  operativeEmail: string | undefined;
  prefixPhone: string | undefined;
  phoneNumber: string | undefined;
  prefixMobilePhone: string | undefined;
  mobilePhoneNumber: string | undefined;
}

export class RepresentativeListResponse {
  items: RepresentativeItem[] | undefined;
  commercialManagementRepresentative: RepresentativeItem | undefined;
}

export class RepresentativeUpdateCommand {
  idRepresentative: number | undefined;
  idHotel: number | undefined;
  idCommercialManagement: number | undefined;
  idCustomer: number | undefined;
  name: string | undefined;
  idRepresentativeDepartement: RepresentativeDepartementEnum | undefined;
  role: string | undefined;
  isMainRepresentative: boolean | undefined;
  operativeEmail: string | undefined;
  prefixPhone: string | undefined;
  phoneNumber: string | undefined;
  prefixMobilePhone: string | undefined;
  mobilePhoneNumber: string | undefined;
}

export class Result {
  isSuccess: boolean | undefined;
  message: string | undefined;
  stacktrace: string | undefined;
  exception: ApplicationException | undefined;
}

export class SalesActivitiesStatus {
  idSalesActivitiesStatus: SalesActivitiesStatusEnum | undefined;
  name: string | undefined;
}

export enum SalesActivitiesStatusEnum {
  FIRST_CONTACT = 1,
  REMINDER = 2,
  INFO_REQUEST = 3,
  NEGOTIATION = 4,
  POSITIVE_CLOSURE = 5,
  NEGATIVE_CLOSURE = 6,
  CRM = 7,
  CM_CONNECTIVITY = 8,
  LINK_CONNECTIVITY = 9,
  OTHER = 10,
}

export class StayType {
  idStayType: StayTypeEnum | undefined;
  name: string | undefined;
}

export enum StayTypeEnum {
  STAY = 1,
  NIGHT = 2,
}

export class TimeZoneItem {
  idTimeZone: number | undefined;
  timeZoneName: string | undefined;
  displayName: string | undefined;
  standardName: string | undefined;
  baseUtcOffset: string | undefined;
}

export class TimeZoneListForDropDownResponse {
  items: TimeZoneSummary[] | undefined;
}

export class TimeZoneListQuery {
}

export class TimeZoneListQueryResponse {
  items: TimeZoneItem[] | undefined;
}

export class TimeZoneSummary {
  idTimeZone: number | undefined;
  displayName: string | undefined;
}

export class TransportHub {
  idTransportHub: number | undefined;
  idTransportHubType: TransportHubTypeEnum | undefined;
  iataCode: string | undefined;
  name: string | undefined;
  cityName: string | undefined;
  cityCode: string | undefined;
  countryName: string | undefined;
  countryCode: string | undefined;
  timezone: string | undefined;
  isInCity: boolean | undefined;
  isManaged: boolean | undefined;
  extendedName: string | undefined;
  iataAndName: string | undefined;
  notes: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}

export class TransportHubCreateCommand {
  idTransportHubType: TransportHubTypeEnum | undefined;
  iataCode: string | undefined;
  name: string | undefined;
  cityCode: string | undefined;
  cityName: string | undefined;
  countryCode: string | undefined;
  countryName: string | undefined;
  timezone: string | undefined;
  isInCity: boolean | undefined;
  isManaged: boolean | undefined;
  extendedName: string | undefined;
  notes: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}

export class TransportHubListForDropDownQueryResponse {
  items: TransportHubSummary[] | undefined;
}

export class TransportHubListItem {
  idTransportHub: number | undefined;
  name: string | undefined;
  iataCode: string | undefined;
  extendedName: string | undefined;
  notes: string | undefined;
  hotelsCount: number | undefined;
}

export class TransportHubListQuery {
}

export class TransportHubListQueryResponse {
  items: TransportHubListItem[] | undefined;
}

export class TransportHubSummary {
  idTransportHub: number | undefined;
  iataAndName: string | undefined;
  iataCode: string | undefined;
  name: string | undefined;
}

export class TransportHubType {
  idTransportHubType: TransportHubTypeEnum | undefined;
  name: string | undefined;
}

export enum TransportHubTypeEnum {
  AIRPORT = 1,
  HARBOR = 2,
  TRAIN_STATION = 3,
  OTHER = 99,
}

export class TransportHubUpdateCommand {
  idTransportHub: number | undefined;
  idTransportHubType: TransportHubTypeEnum | undefined;
  iataCode: string | undefined;
  name: string | undefined;
  cityCode: string | undefined;
  cityName: string | undefined;
  countryCode: string | undefined;
  countryName: string | undefined;
  timezone: string | undefined;
  isInCity: boolean | undefined;
  isManaged: boolean | undefined;
  extendedName: string | undefined;
  notes: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}

export class User {
  idUser: number | undefined;
  authUserRole: AuthUserRole | undefined;
  languageCulture: LanguageCulture | undefined;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  imageUrl: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  lockedDate: moment.Moment | undefined;
  activationDate: moment.Moment | undefined;
}

export class UserCreateCommand {
  idLanguageCulture: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  username: string | undefined;
  password: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  userRole: AuthUserRoleEnum | undefined;
}

export class UserListItem {
  idUser: number | undefined;
  idAuthUser: number | undefined;
  username: string | undefined;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
}

export class UserListQueryResponse {
  items: UserListItem[] | undefined;
}

export class UserUpdateCommand {
  idUser: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  userRole: AuthUserRoleEnum | undefined;
}

export class CommunicationAttachment_Create {
  file: File | undefined;
  id: number | undefined;
}

export class ContractAttachment_Create {
  file: File | undefined;
  id: number | undefined;
}

export class CustomerContractAttachment_Create {
  file: File | undefined;
  id: number | undefined;
}

export class CustomerContractTransportRate_UploadAttachement {
  file: File | undefined;
  id: number | undefined;
}


